import qs from 'qs';
import { isNumber } from './utils';
import { SearchParams } from '../lib/api/getbrands';
import { ProductTags } from '../constants/producttags';
import { ISearchParams, PriceFilter } from '../store/searchparams-context';
import * as Sentry from '@sentry/nextjs';

const _buildParam = (param: string | string[] | number, key: string): string | null => {
  if (typeof param === 'string' && param.length) return `"${key}":"${param}"`;

  if (isNumber(param)) {
    if (key === 'SortOrder' || param > 0) return `"${key}":${param}`;
  }

  return null;
};

const _buildArrayParams = (array: string[] | number[], key: string): null | string => {
  if (Array.isArray(array) && array.length && array[0] !== 0) {
    const str = `"${array.join(`","`)}"`;
    return `"${key}":[${str}]`;
  }

  return null;
};

const _buildQs = (key: string, spModel: string[]): string | null => {
  // @ts-ignore
  return Array.isArray(spModel[key])
    ? // @ts-ignore
      _buildArrayParams(spModel[key], key)
    : // @ts-ignore
      _buildParam(spModel[key], key);
};

// @ts-ignore
const build = (obj): string | undefined | null => {
  if (!obj) return;
  // @ts-ignore
  const queryString = Object.keys(obj)
    .map((key) => _buildQs(key, obj))
    .filter((q) => q || !(q === null || q === undefined));

  if (!queryString.length) return null;

  return `{${queryString.join()}}`;
};

const makeObjectForDropdownFilter = (data: any) => {
  const filters = data?.dropdownFilters.reduce((acc: any, curr: any) => {
    return {
      ...acc,
      [curr.name]: acc[curr.name] ? [...acc[curr.name], curr.value] : [curr.value]
    };
  }, {});
  return {
    ...filters,
    price: [data?.price.min, data?.price.max]
  };
};

export const buildQueryParams = (sp: SearchParams) => {
  const ret: {
    [p: string]: undefined | null | string | unknown;
    page: number | undefined;
    sorting: number;
    gender: string | null | undefined;
    subcat?: string | null;
    tag?: string | null;
    slug?: string | null;
    filter: undefined | null | string;
    score?: number | null;
    q?: string | null;
  } = {
    filter: build(makeObjectForDropdownFilter(sp.selectedFilters)),
    subcat: sp?.subcat,
    tag: sp?.tag,
    slug: sp?.slug,
    page: sp?.page,
    sorting: sp?.selectedFilters.sorting,
    gender: sp?.selectedFilters.gender,
    score: sp?.selectedFilters.overallScore
  };

  // Ignore uninitialized
  return Object.keys(ret)
    .filter((k) => {
      const v = ret[k];

      return (k !== '__typename' && v) || !(v === null || v === undefined);
    })
    .reduce((acc, k) => {
      // @ts-ignore
      acc[k] = ret[k];
      return acc;
    }, {});
};

// TODO dsantic 05.04.2022. This is not correct because of slug!!!
export const buildJournalQueryParams = (page: number, slug: string) => {
  const ret: {
    [p: string]: undefined | null | string | unknown;
    subcat?: string | null;
    tag?: string | null;
    page: number | undefined;
  } = {
    subcat: slug,
    tag: slug,
    page
  };

  // Ignore uninitialized
  return Object.keys(ret)
    .filter((k) => {
      const v = ret[k];

      return (k !== '__typename' && v) || !(v === null || v === undefined);
    })
    .reduce((acc, k) => {
      // @ts-ignore
      acc[k] = ret[k];
      return acc;
    }, {});
};

// export const buildQueryStringForGender = (searchParams: ISearchParamModel): string => {
//   const queryParams = buildQueryParams(searchParams);
//   // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
//   // @ts-ignore
//   if (!queryParams.filter) return '';
//   return qs.stringify(buildQueryParams(searchParams));
// };

export const buildQueryString = (searchParams: SearchParams | null): string => {
  if (!searchParams) return '';
  return qs.stringify(buildQueryParams(searchParams));
};

export const updateLoadMore = (sp: SearchParams, value: number): string => {
  let ret: {
    [p: string]: undefined | null | string | unknown;
    page: number | undefined;
    sorting?: number;
    gender?: string | null | undefined;
    subcat?: string | null;
    tag?: string | null;
    slug?: string | null;
    score?: number | null;
    q?: string | null;
    filter?: undefined | null | string;
  } = {
    page: value
  };

  if (
    //@ts-ignore
    sp?.selectedFilters.dropdownFilters.length > 0 ||
    sp?.selectedFilters.gender ||
    sp?.selectedFilters.sorting !== 1
  ) {
    try {
      const filterParsed = build(makeObjectForDropdownFilter(sp?.selectedFilters));
      // @ts-ignore
      ret = {
        filter: filterParsed,
        subcat: sp?.subcat,
        tag: sp?.tag,
        slug: sp?.slug,
        page: value,
        sorting: sp?.selectedFilters.sorting,
        gender: sp?.selectedFilters.gender,
        score: sp?.selectedFilters.overallScore,
        ...ret
      };
    } catch (e) {
      Sentry.captureException(e);
      Sentry.captureMessage('Error when loading more pages');
      console.error('Error when loading more pages:', e);
    }
  }

  // Ignore uninitialized
  return qs.stringify(
    Object.keys(ret)
      .filter((k) => {
        const v = ret[k];

        return (k !== '__typename' && v) || !(v === null || v === undefined);
      })
      .reduce((acc, k) => {
        // @ts-ignore
        acc[k] = ret[k];
        return acc;
      }, {})
  );
};

export const buildJournalQueryString = (page: number | null, slug: string): string => {
  if (!page) return '';
  return qs.stringify(buildJournalQueryParams(page, slug));
};

export const selectedFiltersCount = (sp: ISearchParams) => {
  const { dropdownFilters, gender, price } = sp?.selectedFilters || {};
  const dropdownCount = dropdownFilters?.length || 0;
  const genderCount = gender ? 1 : 0;
  const priceCount = price?.min !== '0' || price?.max !== '100000' ? 1 : 0;
  const filtersCount = dropdownCount + genderCount + priceCount;
  return filtersCount;
};

export const buildSearchParams = (query: any): SearchParams => {
  const queryObj = qs.parse(query);
  let dropdownFilters;
  let lookupProductTag = null;

  let price: PriceFilter = { min: '0', max: '100000' };

  if (queryObj?.filter) {
    const filters = JSON.parse(queryObj.filter as string);
    dropdownFilters = Object.keys(filters)
      .filter((_) => _ !== 'price')
      .reduce(
        // @ts-ignore
        (previousValue, currentValue) => [
          ...previousValue,
          ...filters[currentValue].map((_: string) => ({ name: currentValue, value: _ }))
        ],
        []
      );

    if (filters.price) {
      const [min, max] = filters.price;
      if (min && max) price = { min, max };
    }
  }

  if (queryObj?.tag !== 'null' && queryObj?.tag) {
    lookupProductTag = ProductTags.find(({ key }) => key === queryObj.tag) || null;
  }

  return {
    selectedFilters: {
      // @ts-ignore
      // tslint:disable-next-line:no-bitwise
      overallScore: queryObj?.score | null,
      gender: (queryObj?.gender as string) || null,
      // @ts-ignore
      dropdownFilters: dropdownFilters || [],
      sorting: Number(queryObj?.sorting) || 1,
      price
    },
    page: queryObj?.page ? Number(queryObj.page) : 1,
    subcat: queryObj?.subcat !== 'null' && queryObj?.subcat ? (queryObj.subcat as string) : null,
    tag: queryObj?.tag !== 'null' && queryObj?.tag ? (queryObj.tag as string) : null,
    lookuptag:
      lookupProductTag?.value && lookupProductTag?.value !== 'null'
        ? (lookupProductTag.value as string)
        : null,
    slug: (queryObj?.slug as string) || null
  };
};

// export const buildURL = (searchParams): string =>
//   `${searchParams.currentUrl}?${buildQueryString(searchParams)}`;

// const getActiveChildrens = (
//   childrenArray: Array<MappedSideBarMenuItem>,
//   accumulator: Array<ActiveSideBars> = []
// ): Array<ActiveSideBars> => {
//   const active = childrenArray.find((_) => _.IsActive);
//
//   if (active && active.GroupId !== '' && GroupIDClassificationMapping[active.GroupId]) {
//     accumulator = {
//       ...accumulator,
//       [GroupIDClassificationMapping[active.GroupId]]: [active.LinkUrl]
//     };
//
//     if (active.Children) return getActiveChildrens(active.Children, accumulator);
//   }
//   return accumulator;
// };

// const getFilterParamsFromSideBar = (sideBar: MappedSideBarMenuItem): {} => {
//   if (sideBar.LinkText === '' && sideBar.Children && sideBar.Children.length) {
//     const [first] = sideBar.Children as Array<MappedSideBarMenuItem>;
//     sideBar = { ...first, IsActive: true };
//   }
//
//   let ret = { ...(sideBar.Children ? getActiveChildrens(sideBar.Children) : []) };
//
//   if (GroupIDClassificationMapping[sideBar.GroupId])
//     ret = {
//       ...ret,
//       [GroupIDClassificationMapping[sideBar.GroupId]]: [sideBar.LinkUrl]
//     };
//
//   return ret;
// };

// const getFilterParamsFromRouteContext = (routeContext: RouteContext): {} => {
//   if (!routeContext) return {};
//
//   const { Brand, Retailer } = routeContext;
//
//   if (Brand)
//     return {
//       [FilterNames.TopBrands]: [Brand.ID.toString()]
//     };
//
//   if (Retailer)
//     return {
//       [FilterNames.TopBrands]: [Retailer.BrandID.toString()],
//       [FilterNames.Retailers]: [Retailer.ID.toString()]
//     };
//
//   return {};
// };

export const isObject = (value: unknown) =>
  value !== null && (typeof value === 'object' || typeof value === 'function');

// export const parseHash = (str: string) => {
//   str = str.trim().replace(/^([?#&])/, '');
//
//   if (!str) return null;
//
//   // eslint-disable-next-line @typescript-eslint/no-explicit-any
//   return (str.split('&') || []).reduce((acc: { [key: string]: any }, currentValue) => {
//     const [key, val] = currentValue.replace(/\+/g, ' ').split('=');
//
//     // @ts-ignore
//     const decodedKey = decodeURIComponent(key).replace('[]', '');
//
//     // @ts-ignore
//     const decodedVal = val ? decodeURIComponent(val) : null;
//
//     if (!acc[decodedKey]) return { ...acc, [decodedKey]: decodedVal };
//
//     if (Array.isArray(acc[decodedKey]))
//       return { ...acc, [decodedKey]: [...acc[decodedKey], decodedVal] };
//
//     return { ...acc, [decodedKey]: [acc[decodedKey], decodedVal] };
//   }, {});
// };

// export const buildParamsFromUrlWithHash = (searchParams: SearchParamModel, hashString: string) => {
//   const hash = parseHash(hashString);
//
//   const removeUndefinedValues = (str: string) => {
//     const undefinedExist = (str: string) => str.indexOf('undefined') > -1;
//
//     let ret = str;
//     if (undefinedExist(ret)) {
//       ret = str.replace('undefined,', '').replace('undefined', '');
//       if (undefinedExist(ret)) removeUndefinedValues(ret);
//     }
//     return ret;
//   };
//
//   // @ts-ignore
//   const mapSearchParamToHashParam = (obj, hash) =>
//     Object.entries(obj).reduce((acc, [key, value]) => {
//       if (Array.isArray(value) && hash[key] !== undefined) {
//         const value = removeUndefinedValues(hash[key]);
//
//         return value !== ''
//           ? { ...acc, [key]: [...acc[key], value] }
//           : { ...acc, [key]: [...acc[key]] };
//       }
//
//       //value is complex, do recursion
//       if (isObject(value)) return { ...acc, [key]: mapSearchParamToHashParam(value, hash) };
//
//       if (hash[key] !== undefined) return { ...acc, [key]: hash[key] };
//
//       return acc;
//     }, obj);
//
//   return mapSearchParamToHashParam(searchParams, hash);
// };

// export const getFilterParams = (sideBar: MappedSideBarMenuItem, routeContext: RouteContext): {} => {
//   try {
//     return {
//       ...getFilterParamsFromSideBar(sideBar),
//       ...getFilterParamsFromRouteContext(routeContext)
//     };
//   } catch (e) {
//     //TODO dsantic 30/10/2019 Log this case to graylog
//     return {};
//   }
// };
