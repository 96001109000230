export const BRANDS_ORDER = [
  'LANIUS',
  'Komodo',
  'Le Pirol',
  'Stella McCartney',
  'PLANT FACED CLOTHING',
  'Haikure',
  'Veja',
  'OGNX',
  'Nina Rein',
  'REER3',
  'Designers Remix',
  'eyd',
  'Blutsgeschwister',
  'FTC Cashmere',
  'Living Crafts',
  'UNDERPROTECTION',
  'Patagonia',
  'Nanushka',
  'The North Face',
  'Chinti & Parker'
].map((_) => _.toLowerCase());
