import React from 'react';
import styles from './buttons.module.scss';

interface Props {
  onClose: (event?: any) => void;
  customClass?: string;
}

export const CloseButton = ({ onClose, customClass = '' }: Props) => (
  <div onClick={onClose} className={` ${styles.button} ${customClass}`}>
    <img className={'float-right'} src={'/assets/icons/close-black.svg'} alt='close' />
  </div>
);
