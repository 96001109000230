/* eslint-disable */
import { useState, useEffect } from 'react';

const useDebounce = <T>(value: T, delay: number): T => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    if (Array.isArray(value) && Array.isArray(debouncedValue)) {
      if (!value.length && !debouncedValue.length) return;
      if (value[0] === debouncedValue[0]) return;
    }
    if (value === debouncedValue) return;
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
};

export default useDebounce;
