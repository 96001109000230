/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from 'react'; // eslint-disable-line
import PropTypes from 'prop-types';
import MenuItems from './menuitems';
import Sidebar from 'react-sidebar';
import { CloseButton } from '../buttons/closeButton';
import styles from './hamburgermenu.module.scss';
import { BurgerMenuIcon } from '../../public/assets/icons/burger-menu-icon';
import { useSearchParams } from '../../store/searchparams-context';

interface Props {
  menuItems: any;
  backgroundColor: string;
  textColor: string;
  iconColor: string;
  hamBurgerClassName: string;
  className: string;
  animation: string[];
  menuContainerWidth: string;
}

const MenuBar = ({ menuItems: data = [], iconColor }: Props) => {
  const [showMenuItems, changeShowMenuItems] = useState(false);

  const { resetSelectedFilters } = useSearchParams();
  // @ts-ignore
  const onClick = () => {
    changeShowMenuItems(false);
    resetSelectedFilters();
  };

  useEffect(() => {
    showMenuItems
      ? document.body.setAttribute('style', 'overflow: hidden')
      : document.body.setAttribute('style', 'overflow: visible');
  }, [showMenuItems]);

  // @ts-ignore
  const generateBack = (passedData, level) => {
    if (level > 0 && passedData[0].value !== 'back') {
      passedData.unshift({
        value: 'back'
      });
    }
    for (const item of passedData) {
      if (item.items && item.items.length > 0) {
        generateBack(item.items, (level += 1));
      }
    }
  };
  generateBack(data, 0);

  // @ts-ignore
  const showItemsHandler = (event) => {
    event.stopPropagation();
    changeShowMenuItems(!showMenuItems);
  };

  const closeItemsHandler = () => {
    changeShowMenuItems(false);
  };

  const sideBarContent = () => {
    return (
      <div className={`${styles.menu}`}>
        <div className='float-right'>
          {/*<BackButton onClose={showItemsHandler} />*/}
          <CloseButton onClose={showItemsHandler} />
        </div>
        <MenuItems showMenuItems={true} Data={data} onClick={onClick} />
      </div>
    );
  };

  return (
    <>
      <span
        onClick={showItemsHandler}
        className={
          iconColor !== 'black' ? styles.menuIconContainerForBlack : styles.menuIconContainerForGray
        }
      >
        <BurgerMenuIcon color={iconColor} />
      </span>
      <Sidebar
        sidebar={sideBarContent()}
        shadow={true}
        docked={false}
        touch={false}
        open={showMenuItems}
        children={<span />}
        onSetOpen={closeItemsHandler}
        sidebarClassName={styles.menuwrapper}
        rootClassName={'d-flex flex-column no-gutters'}
        styles={{
          sidebar: {
            background: 'white',
            position: 'fixed',
            zIndex: '200'
          }
        }}
      />
    </>
  );
};

MenuBar.defaultProps = {
  data: [
    {
      value: 'No data found'
    }
  ],
  animation: ['slideIn', 'slideOut'],
  backgroundColor: '#4dccc4',
  textColor: 'white',
  iconColor: 'white',
  menuContainerWidth: 300,
  className: '',
  hamBurgerClassName: ''
};

MenuBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  animation: PropTypes.arrayOf(PropTypes.string),
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  menuContainerWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  hamBurgerClassName: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string
};

export default MenuBar;
