import { ProductProps, ProductType } from '../components/productlistcontainer/products.types';
import { EventData } from '@bettercart/react-facebook-pixel';

export type FacebookProps = {
  event: string;
  data?: Data;
  eventData?: EventData;
};

type Data = {
  content_name?: string;
  content_category?: string;
  content_type?: string | undefined;
  content_ids?: string[] | string | null;
  search_string?: string;
  currency?: string;
  value?: number;
};

export const EVENT = {
  VIEWCONTENT: 'ViewContent',
  PURCHASE: 'Purchase'
};

export const getFacebookTrackingData = (fbData: FacebookProps) => {
  const data: Data = {};
  if (!fbData || !fbData?.event) return null;

  if (fbData?.data) {
    if (fbData.data.content_name) data.content_name = fbData.data.content_name;
    if (fbData.data.content_type) data.content_type = fbData.data.content_type;
    if (fbData.data.content_category) data.content_category = fbData.data.content_category;
    if (fbData.data.content_ids) data.content_ids = fbData.data.content_ids;

    if (fbData.data.currency && fbData.data.value) {
      data.currency = fbData.data.currency;
      data.value = fbData.data.value;
    }
  }

  if (fbData?.eventData && fbData?.eventData?.eventID) {
    return {
      event: fbData.event,
      data,
      eventData: fbData.eventData
    };
  }

  return {
    event: fbData.event,
    data
  };
};

export const getProductIds = (products: ProductProps[]) => {
  const ids: string[] = [];
  if (!products) return null;

  if (products && products.length > 0) {
    products.map((item: ProductProps) => {
      const source: ProductType | undefined = item?._source;
      if (source) ids.push(source?.productId);
    });
  }

  if (ids && ids.length > 0) return ids?.slice(0, 5) || [];

  return null;
};

export const buildCategory = (
  subcat: string | null | undefined,
  tag: string | null | undefined
) => {
  const category = [];
  if (subcat) category.push(subcat);
  if (tag) category.push(tag);

  if (category && category.length > 0) return category.join('/');

  return '';
};

export const buildEventId = (id: string) => {
  if (!id) return undefined;

  return `${id}-${Date.now().toString()}-${Math.floor(Math.random() * 99999999) + 1}`;
};

export const handleFbClickout = (searchString: string): void | boolean => {
  if (window?.uc_consent?.Facebook_Pixel) {
    import('@bettercart/react-facebook-pixel')
      .then((_) => _.default)
      .then((ReactFacebookPixel) => {
        ReactFacebookPixel.track('Search', { search_string: searchString });
      })
      .catch((err) => {
        console.error('Error tracking Search with Facebook pixel', err);
      });
  }

  return true;
};
