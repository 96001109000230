import styles from './navigation.module.scss';
import MenuBar from '../hamburgermenu';
import React, { useState } from 'react';
import SearchOverlay from '../searchoverlay/searchoverlay';
// import { menuState } from '../../lib/recoilatoms';
import { SearchIcon } from '../../public/assets/icons/search';
import { Image } from 'react-bootstrap';
import Link from 'next/link';

import { MENU } from '../../constants/menu';

type Props = {
  iconColor?: string;
  isHome?: boolean;
};

export default function Navigation({ iconColor = 'black', isHome }: Props) {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <div className={styles.wrapper}>
      {!modalVisible && (
        <MenuBar
          menuItems={MENU}
          backgroundColor={'white'}
          textColor={'black'}
          iconColor={iconColor}
          hamBurgerClassName={'d-flex flex-column hamburgermenu'}
        />
      )}
      {isHome ? (
        <Image
          src={`/assets/images/fairlyfab-logo-black.png`}
          alt={'fairlyfab-logo-black'}
          className={styles.logoBlack}
        />
      ) : (
        <Link href='/'>
          <a className='no-anchor-decoration'>
            <Image
              src={'/assets/images/fairlyfab-logo-black.png'}
              alt={'fairlyfab-logo-black'}
              className={styles.logoBlack}
            />
          </a>
        </Link>
      )}

      <div
        className={
          iconColor !== 'black'
            ? styles.searchIconContainerForBlack
            : styles.searchIconContainerForGray
        }
      >
        <SearchIcon color={iconColor} />
        <span
          className={styles.searchIcon}
          onClick={() => {
            setModalVisible(true);
            document.body.style.position = 'fixed';
          }}
        />
      </div>
      <SearchOverlay
        show={modalVisible}
        onClose={(ev) => {
          setModalVisible(ev);
          document.body.style.position = '';
        }}
      />
    </div>
  );
}
