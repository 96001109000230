export const FILTERS = {
  KRITERIEN: 'Kriterien', // criteries
  KATEGORIEN: 'Kategorien', // categories
  SIEGEL: 'Siegel', // certificates
  PREIS: 'Preis', // price
  STANDORT: 'Standort', // standort
  VERSAND: 'Versand nach' // versand
};

export const CERTIFICATE_WORDINGS: { [index: string]: string } = {
  GOTS: 'Global Organic Textile Standard (GOTS)',
  PETA: 'PETA-Approved Vegan',
  GRS: 'Global Recycled Standard (GRS)',
  'Standard 100': 'OEKO-TEX Standard 100',
  Fairtrade: 'Fairtrade',
  'B Corp': 'Certified B Corporation',
  'Fair Wear': 'Fair Wear Foundation',
  bluesign: 'bluesign'
};

export const GRADING_LETTERS = {
  A: 'A',
  B: 'B',
  C: 'C'
};

export const RATING_NUMBERS: { [key: string]: number } = {
  great: 5,
  good: 4,
  itsastart: 3,
  notgoodenough: 2,
  verypoor: 1,
  notstarted: 0,
  notapplicable: 999,
  notassessed: 999
};

export const RETAILER = {
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};
