export const MENU = [
  {
    link: '/bekleidung/',
    value: 'Bekleidung',
    items: null
    // items: [
    //   { link: '/bekleidung', value: 'Alle', items: null },
    //   { link: '/t-shirts', value: 'T-Shirts', items: null },
    //   { link: '/jacken-maentel', value: 'Jacken & Mäntel', items: null },
    //   { link: '/bademode', value: 'Bademode', items: null },
    //   { link: '/jeans', value: 'Jeans', items: null },
    //   { link: '/pullover-strickmode', value: 'Pullover & Strickmode', items: null },
    //   { link: '/hoodies-sweatshirts', value: 'Hoodies & Sweatshirts', items: null },
    //   { link: '/kleider', value: 'Kleider', items: null },
    //   { link: '/hosen', value: 'Hosen', items: null },
    //   { link: '/unterwaesche-dessous', value: 'Unterwäsche & Dessous', items: null }
    //   // { link: '/umstandsmode', value: 'Umstandsmode', items: null }
    // ]
  },
  {
    link: '/schuhe/',
    value: 'Schuhe',
    items: null
    // items: [
    //   { link: '/schuhe', value: 'Alle', items: null },
    //   { link: '/sneaker', value: 'Sneaker', items: null },
    //   { link: '/stiefel', value: 'Stiefel', items: null },
    //   { link: '/sandalen', value: 'Sandalen', items: null }
    // ]
  },
  // {
  //   link: '/accessoires',
  //   value: 'Accessoires',
  //   // items: [
  //   //   { link: '/accessoires', value: 'Alle', items: null },
  //   //   { link: '/taschen', value: 'Taschen', items: null },
  //   //   { link: '/rucksaecke', value: 'Rucksäcke', items: null },
  //   //   { link: '/uhren-schmuck', value: 'Uhren & Schmuck', items: null },
  //   //   { link: '/sonnenbrillen', value: 'Sonnenbrillen', items: null },
  //   //   { link: '/muetzen', value: 'Mützen', items: null }
  //   // ]
  // },
  { link: '/sportmode/', value: 'Sport', items: null },
  { link: '/marken/', value: 'Marken', items: null },
  { link: '/magazin/', value: 'Magazin', items: null },
  {
    link: null,
    value: 'Philosophie',
    items: [
      { link: '/about/unsere-werte/', value: 'Unsere Werte', items: null },
      { link: '/about/wie-wir-bewerten/', value: 'Wie wir bewerten', items: null },
      { link: '/about/siegel-und-kriterien/', value: 'Siegel und Kriterien', items: null }
    ]
  }
];
