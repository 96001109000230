import { Brands, ESBrandDetailData } from '../types/elasticsearch';
import { NextRouter } from 'next/router';
import { CPC, SETTINGS } from '../constants/settings';
import { RATING_NUMBERS } from '../constants/filters';
import { ISearchParams } from '../store/searchparams-context';
import { buildQueryString } from './searchparams';
import { API } from '../constants/api';
import * as sizes from '../constants/sizes';
import { BRANDS_ORDER } from '../constants/brandsOrder';

export const getCssClasses = (classes: string[]) => `${classes.join(' ')}`;

export const brandRating = (rating: number) => {
  const ratingObject = {
    A: 5,
    B: 4,
    C: 3
  };

  // @ts-ignore
  const ratingLetter = Object.keys(ratingObject).find((key) => ratingObject[key] === rating);

  return ratingLetter;
};

export const brandPrice = (price: number) => {
  return Array(price + 1).join('€');
};

export const getWordRating = (grade: number) => {
  let wordRating = '';
  if (grade === 5) {
    wordRating = 'Sehr gut';
  } else if (grade === 4) {
    wordRating = 'Gut';
  } else {
    wordRating = 'Ein guter Start';
  }

  return wordRating;
};

export const changeImgSrc = (imgSrc: string) => {
  if (process.env.NODE_ENV === 'production') {
    return (imgSrc || '').split('.com')[1];
  }

  return imgSrc;
};

export const prepareBrands = (
  brands: Brands[],
  specialBrands: Brands[],
  filteredBrands: ESBrandDetailData[] | null,
  selectedSorting: number | string,
  isSearchInputEmpty: boolean
) => {
  let allBrands: Brands[];
  const convertedFilteredBrands = (filteredBrands || []).map((el) => ({
    id: el.id,
    title: el.header.brandName,
    details: {
      overallRating: el.header.overallRating,
      price: el.details.price,
      brandName: el.header.brandName
    },
    uri: el.uri,
    featuredImage: el.featuredImage,
    publishDate: el.publishDate
  }));
  if (filteredBrands && filteredBrands?.length >= 0) allBrands = [...convertedFilteredBrands];
  else if (!isSearchInputEmpty) allBrands = [];
  else
    allBrands = [...specialBrands, ...brands].filter(
      (brand, index, self) =>
        index === self.findIndex((t) => t.details.brandName === brand.details.brandName)
    );

  let sortedBrands: Brands[];

  switch (selectedSorting) {
    case '1':
      // Favoriten - this is the default sorting which comes as standard from ElasticSearch
      sortedBrands = allBrands;
      break;
    case '2':
      // Neu
      sortedBrands = allBrands.sort((a, b) =>
        a.publishDate > b.publishDate ? -1 : b.publishDate > a.publishDate ? 1 : 0
      );
      break;
    case '3':
      // Top Ranking
      sortedBrands = allBrands.sort((a, b) =>
        a.details.overallRating > b.details.overallRating
          ? -1
          : b.details.overallRating > a.details.overallRating
          ? 1
          : 0
      );
      break;
    case '4':
      // Name A-Z
      sortedBrands = allBrands.sort((a, b) =>
        a.details.brandName.toLowerCase() > b.details.brandName.toLowerCase()
          ? 1
          : b.details.brandName.toLowerCase() > a.details.brandName.toLowerCase()
          ? -1
          : 0
      );
      break;
    case '5':
      // Name Z-A
      sortedBrands = allBrands.sort((a, b) =>
        a.details.brandName.toLowerCase() > b.details.brandName.toLowerCase()
          ? -1
          : b.details.brandName.toLowerCase() > a.details.brandName.toLowerCase()
          ? 1
          : 0
      );
      break;
    case '6':
      // Preis Absteigend (max-min)
      sortedBrands = allBrands.sort((a, b) =>
        a.details.price > b.details.price ? -1 : b.details.price > a.details.price ? 1 : 0
      );
      break;
    case '7':
      // Preis Aufsteigend (min-max)
      sortedBrands = allBrands.sort((a, b) =>
        a.details.price > b.details.price ? 1 : b.details.price > a.details.price ? -1 : 0
      );
      break;
    default:
      sortedBrands = allBrands;
  }

  return sortedBrands;
};

interface QueryArray {
  match: { [x: string]: string | number };
}

const bekleidungExtraCategories = [
  'Blusen',
  'Hemden',
  'Hoodies',
  'Hosen',
  'T-Shirts',
  'Unterwäsche',
  'Longsleeves',
  'Poloshirts'
];

const bekleidungCategories = [
  'Jeans',
  'Pullover und Strick',
  'Tops',
  'Basics und Dessous',
  'Röcke und Hosen',
  'Kleider und Overalls',
  'Bademode',
  'Jacken und Mäntel',
  'Nachtwäsche',
  'Anzüge',
  'Große Größen',
  'Umstandsmode'
];

const mapExtraCategories = (cats: string[], filterShould: QueryArray[]): void =>
  cats.forEach((extraCat) => {
    filterShould.push({
      match: { 'extra_categories.name': extraCat }
    });
  });

const mapCategories = (cats: string[], filterShould: QueryArray[]): void =>
  cats.forEach((cat) => {
    filterShould.push({
      match: { 'categories.name': cat }
    });
  });

export const getFilterQuery = (slug: string) => {
  if (slug === '') return;

  const filterShouldQuery: QueryArray[] = [];

  mapExtraCategories(bekleidungExtraCategories, filterShouldQuery);
  mapCategories(bekleidungCategories, filterShouldQuery);

  if (slug === 'bekleidung')
    return {
      bool: {
        should: filterShouldQuery
      }
    };

  if (slug === 'schuhe')
    return {
      bool: {
        should: [
          {
            match: {
              'categories.name': 'Schuhe'
            }
          },
          {
            match: {
              'extra_categories.name': 'Sneaker'
            }
          },
          {
            match: {
              'extra_categories.name': 'Sandalen'
            }
          },
          {
            match: {
              'extra_categories.name': 'Stiefeletten'
            }
          }
        ]
      }
    };

  if (slug === 'sport')
    return {
      match: {
        'categories.name': 'Sportbekleidung'
      }
    };
};

/*
   Pages that are statically optimized by Automatic Static Optimization will be hydrated without their route parameters provided, i.e router.query will be an empty object ({}).
   After hydration, Next.js will trigger an update to your application to provide the route parameters in the query object.
   But we don't want that. We want to have query param on first render, hence this function as workaround.
   https://nextjs.org/docs/routing/dynamic-routes#caveats
 */
export const getQueryParamOnFirstRender = (paramKey: string, router: NextRouter): string => {
  const checkFromPath = router.asPath.match(new RegExp(`${paramKey}=([^&#])*`));

  if (checkFromPath && checkFromPath.length > 0) return checkFromPath[0].split('=')[1] ?? null;
  else return router.query[paramKey] as string;
};

export const getPathnameWithoutParams = (path: string): string => path.replace(/\?.*/g, '');

export const buildUrlWithPageParameter = (page: number, { asPath, locale }: NextRouter): string => {
  const pathname = getPathnameWithoutParams(asPath);

  const currentUrl = `${SETTINGS.LIVE_HOST}/${locale}${pathname}`;
  if (page > 1) return `${currentUrl}?page=${page}`;

  return currentUrl;
};

export const isObject = (value: number | string | string[]): boolean =>
  value !== null && (typeof value === 'object' || typeof value === 'function');

export const getTag = (value: number | string | string[]): string => {
  const toString = Object.prototype.toString;

  if (value === null) {
    return '[object Null]';
  }
  return toString.call(value);
};

export const isPlainObject = (value: any): boolean => {
  if (!isObject(value) || getTag(value) !== '[object Object]') {
    return false;
  }
  if (Object.getPrototypeOf(value) === null) {
    return true;
  }
  let proto = value;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }
  return Object.getPrototypeOf(value) === proto;
};

export const isNumber = (value: number | string | string[]): boolean => {
  if (typeof value === 'number') return true;
  // @ts-ignore
  if (typeof value === 'string' && !isNaN(value) && value.substr(0, 2) !== '0x') return true;
  if (isObject(value) && getTag(value) === '[object Number]') return true;

  return false;
};

export const getRatingNumber = (ratingInput: string) => {
  const rat = (ratingInput || '').replace(/[^a-zA-Z0-9]/g, '').toLowerCase();

  if (rat) {
    return RATING_NUMBERS[rat];
  } else {
    return 1;
  }
};

export const fetchFiltersDataBySearchParams = async (params: ISearchParams) => {
  const qs = buildQueryString(params);
  const res = await fetch(`${API.FILTERS_DATA}?${qs}`);
  const data = await res.json();
  return data;
};

export const setSizeOrder = (
  allSizes: string[],
  subcat: string | undefined,
  tag: string | undefined
) => {
  const { NUM_SIZES_SET_1, NUM_SIZES_SET_2, EU_SIZES, IT_SIZES, LETTER_SIZES } = sizes;
  const numSet1 = allSizes.filter((e) => NUM_SIZES_SET_1.includes(+e)).sort();
  const numSet2 = allSizes.filter((e) => NUM_SIZES_SET_2.includes(+e)).sort();
  const EUSize = allSizes.filter((e) => EU_SIZES.includes(e)).sort();
  const ITSize = allSizes.filter((e) => IT_SIZES.includes(e)).sort();
  const letterSize = allSizes
    .filter((e) => LETTER_SIZES.includes(e))
    .sort((a, b) => LETTER_SIZES.indexOf(a) - LETTER_SIZES.indexOf(b));
  return tag === 'jeans'
    ? [
        ...numSet1,
        ...letterSize,
        ...allSizes.filter((e) => ![...numSet1, ...letterSize].includes(e))
      ]
    : subcat === 'schuhe'
    ? [...EUSize, ...ITSize, ...allSizes.filter((e) => ![...EUSize, ...ITSize].includes(e))]
    : [
        ...letterSize,
        ...numSet2,
        ...allSizes.filter((e) => ![...letterSize, ...numSet2].includes(e))
      ];
};

export const setBrandsOrder = (allBrands: string[]) => {
  const priorityBrands = allBrands
    .filter((e) => BRANDS_ORDER.includes(e.toLowerCase()))
    .sort((a, b) => BRANDS_ORDER.indexOf(a.toLowerCase()) - BRANDS_ORDER.indexOf(b.toLowerCase()));
  const nonPriorityBrands = allBrands.filter((e) => !priorityBrands.includes(e));
  return [...priorityBrands, ...nonPriorityBrands];
};

export const getNewCPCForDate = () => {
  if (new Date(`${process.env.NEXT_PUBLIC_CPC_DATE_CHANGE}`) < new Date()) {
    return CPC.FF_RETAILER_NEW;
  } else return CPC.FF_RETAILER;
};
