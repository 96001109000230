const set1From = 24;
const set2From = 34;
// 24-46
export const NUM_SIZES_SET_1 = Array.from({ length: 23 }, (_, i) => i + set1From);

// 34-46
export const NUM_SIZES_SET_2 = Array.from({ length: 14 }, (_, i) => i + set2From).filter(
  (a) => a % 2 === 0
);

// EU 35 - EU 46
export const EU_SIZES = Array.from({ length: 12 }, (_, i) => 'EU ' + (i + 35));

// IT35-IT46
export const IT_SIZES = EU_SIZES.map((size) => size.replace('EU ', 'IT'));

// standard sizes
export const LETTER_SIZES = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
