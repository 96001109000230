import React, { useEffect, useState } from 'react';
import styles from './menuItem.module.scss';

// @ts-ignore
const MenuItem = ({ item, moveToNext, onClick }) => {
  const [screenWidth, setScreenWidth] = useState(500);
  useEffect(() => setScreenWidth(window?.innerWidth ?? 0), []);

  //ugly fix for Siegel und Kriterien break
  const breakString = (str: string) => {
    if (str === 'Siegel und Kriterien') {
      const strArr = str.split('und');
      return strArr[0] + '<br>und' + strArr[1];
    }
    return str;
  };

  if (item.link) {
    return (
      <div className='menuItem' onClick={() => onClick(item)}>
        {screenWidth < 1560 ? (
          <a
            href={`/de-DE/${item.link}`}
            className={styles.LinkStyle}
            dangerouslySetInnerHTML={{
              __html: breakString(item.value)
            }}
          />
        ) : (
          <a href={`/de-DE/${item.link}`} className={styles.LinkStyle}>
            {item.value}
          </a>
        )}
      </div>
    );
  }
  return (
    <div className='menuItem' onClick={() => moveToNext(item)}>
      <p className={styles.paddingStyle}>{item.value}</p>
      {/*<p className='nextArrow' style={{ display: nextValue ? 'block' : 'none' }}>*/}
      {/*  <RightArrow color={textColor} />*/}
      {/*</p>*/}
    </div>
  );
};

export default MenuItem;
