export const SearchIcon = ({ color }: { color: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 28'>
      <g fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g stroke={color} strokeWidth='2'>
          <g>
            <g>
              <path
                d='M10.111 0c5.584 0 10.111 4.527 10.111 10.111 0 5.585-4.527 10.112-10.11 10.112C4.526 20.223 0 15.696 0 10.11 0 4.527 4.527.001 10.111.001zM26 26L17.333 17.334'
                transform='translate(-1845 -40) translate(1846 41)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
