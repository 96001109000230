export const BurgerMenuIcon = ({ color }: { color: string }) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 36 28'>
      <g fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g stroke={color} strokeWidth='2'>
          <g>
            <g>
              <path
                d='M0 1.625L32.5 1.625M0 13.056L32.5 13.056M0 24.486L32.5 24.486'
                transform='translate(-49 -41) translate(50 41)'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
