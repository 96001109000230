/* eslint-disable jsx-a11y/no-autofocus */
import React, { ReactElement, useEffect, useState } from 'react';
import styles from './searchinput.module.scss';
import { Col, Row } from 'react-bootstrap';
import { SearchInputTypes } from './searchinput.types';
import useDebounce from '../../hooks/useDebounce';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';

const SearchInput = (props: SearchInputTypes): ReactElement => {
  const {
    isSearchOverlay = false,
    setBrands,
    setJournals,
    setBrandPageBrands,
    setIsSearchInputEmpty,
    setTotalBrands,
    page
  } = props;
  const [screenWidth, setScreenWidth] = useState(500);
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearch = useDebounce<string>(searchTerm, 300);
  const { asPath, query } = useRouter();

  const handleInputChange = (e: React.ChangeEvent): void => {
    const searchTerm = (e.target as HTMLInputElement).value;
    setSearchTerm(searchTerm);
    if (searchTerm === '' && setIsSearchInputEmpty) setIsSearchInputEmpty(true);
    if (searchTerm === '' && setBrands) setBrands([]);
    if (searchTerm === '' && setJournals) setJournals([]);
    // if (searchTerm === '' && setCategories) setCategories([]);
    if (searchTerm === '' && setBrandPageBrands) setBrandPageBrands([]);
    if (searchTerm === '' && setTotalBrands) setTotalBrands(null);
  };

  useEffect(() => setScreenWidth(window?.innerWidth ?? 0), []);

  useEffect(() => {
    // Reset search input to prevent keeping search results when switching different category pages
    setSearchTerm('');

    if (setIsSearchInputEmpty) setIsSearchInputEmpty(true);
    if (setBrands) setBrands([]);
    if (setJournals) setJournals([]);
    if (setBrandPageBrands) setBrandPageBrands([]);
    // if (setCategories) setCategories([]);
    const searchInput = document?.querySelector('#search-input') as HTMLInputElement;
    if (searchInput) searchInput.value = '';
  }, [asPath]);

  useEffect((): void => {
    // This one is for Search overlay component
    if (debouncedSearch.length && isSearchOverlay)
      fetch(`/api/search?searchterm=${debouncedSearch}`)
        .then((res) => res.json())
        .then((data) => {
          if (setBrands) setBrands(data.brands);
          if (setJournals) setJournals(data.journals);
          // if (setCategories) setCategories(data.categories);
          if (setIsSearchInputEmpty) setIsSearchInputEmpty(false);
        })
        .catch((e) => {
          Sentry.captureException(e);
          Sentry.captureMessage('Error when requesting search results in search overlay');
          console.error('Error when requesting search results:', e);
        });
    // This one is for list pages (marken, category, ...)
    if (debouncedSearch.length)
      fetch(`/api/search?brand=${debouncedSearch}&slug=${query.slug}&page=${page}`)
        .then((res) => res.json())
        .then((data) => {
          if (setBrandPageBrands) setBrandPageBrands(data.brands);
          if (setTotalBrands) setTotalBrands(data.total);
          if (setIsSearchInputEmpty && debouncedSearch !== '') setIsSearchInputEmpty(false);
          else if (setIsSearchInputEmpty) setIsSearchInputEmpty(true);
        })
        .catch((e) => {
          Sentry.captureException(e);
          Sentry.captureMessage('Error when requesting search results in marken page');
          console.error('Error when requesting search results:', e);
        });
  }, [debouncedSearch]);
  return (
    <Row
      className={`justify-content-center pt-3 pb-4 pt-md-5 pb-md-5 ${
        isSearchOverlay ? styles.searchOverlayRow : styles.searchBrandsRow
      }`}
      id='search'
    >
      <Col xs={12} className='text-center'>
        <input
          id='search-input'
          type='text'
          className={`${isSearchOverlay ? styles.searchOverlayInput : styles.searchNormalInput} ${
            styles.searchBrandsInput
          }`}
          placeholder={
            screenWidth < 768
              ? 'Durchsuchen...'
              : isSearchOverlay
              ? 'Marken und Magazin durchsuchen...'
              : 'Marken finden...'
          }
          onChange={(e) => handleInputChange(e)}
          autoFocus={isSearchOverlay}
        />
      </Col>
    </Row>
  );
};

export default SearchInput;
