import React from 'react';
import styles from './buttons.module.scss';

interface Props {
  onClose: (event?: any) => void;
}

export const BackButton = ({ onClose }: Props) => (
  <div onClick={onClose} className={`d-flex justify-content-start ${styles.backButton}`}>
    <img className={'float-right'} src={'/assets/icons/back-icon.svg'} alt='back' />
  </div>
);
