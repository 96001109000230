import React, { ReactElement, useEffect, useState } from 'react';
import styles from './searchoverlay.module.scss';
import { SearchoverlayTypes } from './searchoverlay.types';
import SearchInput from '../searchinput/searchinput';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { ESBrandDetailData } from '../../types/elasticsearch';

import { SearchJournals } from '../searchinput/searchinput.types';
import { changeImgSrc } from '../../utils/utils';
import { handleFbClickout } from '../../lib/fb';

const SearchOverlay = (props: SearchoverlayTypes): ReactElement | null => {
  const { show, onClose } = props;
  const [brands, setBrands] = useState<ESBrandDetailData[]>([]);
  const [journals, setJournals] = useState<SearchJournals[]>([]);
  // const [categories, setCategories] = useState<SearchCategories[]>([]);
  const [isSearchInputEmpty, setIsSearchInputEmpty] = useState<boolean>(true);

  useEffect(() => {
    const close = (e: any) => {
      if (e.keyCode === 27) {
        onClose(false);
      }
    };
    window.addEventListener('keydown', close);
    return () => window.removeEventListener('keydown', close);
  }, []);

  const handleClick = (
    e: React.MouseEvent<HTMLAnchorElement>,
    url: string | undefined,
    searchString: string | undefined
  ) => {
    e.preventDefault();
    handleFbClickout(searchString || '');
    window.location.href = `/de-DE/${url}` || '/';
  };

  const renderBrands = (brands: ESBrandDetailData[]): ReactElement[] =>
    brands?.map((brand) => (
      <Row key={brand.slug}>
        <Col>
          <a
            href={brand?.uri}
            className={styles.brandName}
            onClick={(e) => handleClick(e, brand?.uri, brand?.header?.brandName)}
          >
            {brand?.header?.brandName}
          </a>
        </Col>
      </Row>
    )) ?? [];

  // const renderCategories = (categories: SearchCategories[]): ReactElement[] =>
  //   categories?.map((category) => (
  //     <Row key={category.slug}>
  //       <Col>
  //         <a
  //           href={`/de-DE/${category?.slug}`}
  //           className={styles.brandName}
  //           onClick={(e) => handleClick(e, category?.slug, category?.title)}
  //         >
  //           {category?.parentCategory
  //             ? `${category?.parentCatBreadcrumb} > ${category?.breadcrumbName}`
  //             : category?.breadcrumbName}
  //         </a>
  //       </Col>
  //     </Row>
  //   )) ?? [];

  const renderJournals = (journals: SearchJournals[]): ReactElement[] =>
    journals?.map((journal) => (
      <Col xs={6} md={4} lg={3} className='pl-0 pr-3 pr-md-3' key={journal.title}>
        <a
          href={journal?.uri}
          className={styles.journalName}
          onClick={(e) => handleClick(e, journal?.uri, journal?.title)}
        >
          <Row className={styles.journalRow}>
            <Col xs={12}>
              <Image
                fluid
                src={changeImgSrc(journal?.featuredImage)}
                className={`imgFix ${styles.journalImage}`}
                alt={
                  journal?.featuredImageAlt
                    ? journal?.featuredImageAlt
                    : journal?.featuredImageTitle
                }
              />
            </Col>
            <Col xs={12} className={styles.journalTitle}>
              {journal?.title}
            </Col>
          </Row>
        </a>
      </Col>
    )) ?? [];

  const renderResults = (): ReactElement => (
    <>
      <Container fluid className={styles.brandContainer}>
        <Row noGutters className='justify-content-center'>
          {brands && brands.length > 0 && (
            <Col xs={10} md={6} className={styles.brands}>
              <p className='justify-content-center'>Marken</p>
              {renderBrands(brands)}
            </Col>
          )}
          {/*{categories && categories.length > 0 && (*/}
          {/*  <Col xs={10} md={brands.length > 0 ? 3 : 6} className={styles.brands}>*/}
          {/*    <p className='justify-content-center'>Kategorien</p>*/}
          {/*    {renderCategories(categories)}*/}
          {/*  </Col>*/}
          {/*)}*/}
        </Row>
        <Row noGutters className='d-none d-lg-flex justify-content-center'>
          {journals && journals.length > 0 && (
            <Col xs={10} md={6} className={`${styles.slider}`}>
              <p className='justify-content-center'>Magazine</p>
              <Row noGutters className={styles.journalWrapper}>
                {renderJournals(journals)}
              </Row>
            </Col>
          )}
        </Row>
      </Container>

      <Container fluid className={`d-block d-lg-none ${styles.journalContainer}`}>
        {journals && journals.length > 0 && (
          <Col className={`pl-2 ${styles.slider}`}>
            <p className='justify-content-center'>Magazine</p>
            <Row noGutters className={styles.journalWrapper}>
              {renderJournals(journals)}
            </Row>
          </Col>
        )}
      </Container>
    </>
  );

  const renderEmptyResults = (): ReactElement => (
    <Container fluid>
      <Row noGutters className='justify-content-center'>
        <Col xs={10} md={6}>
          <div className={styles.noResults}>Kein Treffer</div>
        </Col>
      </Row>
      <Row noGutters className='justify-content-center mt-5'>
        <Col xs={10} md={6}>
          <p>Deine nachhaltige Lieblingsmarke ist noch nicht bei fairlyfab gelistet?</p>
          <span>Schicke uns deinen Vorschlag hier:</span>{' '}
          <a
            className={`no-anchor-decoration ${styles.brandRanking}`}
            href='https://www.surveymonkey.de/r/36K2HM3'
            target='_blank'
            rel='noreferrer'
          >
            {' '}
            Marke vorschlagen{' '}
          </a>
        </Col>
      </Row>
    </Container>
  );

  if (!show) return null;
  else {
    return (
      <div className={styles.overlay}>
        <span className={styles.closeButton} onClick={() => onClose(false)} />
        <SearchInput
          isSearchOverlay={true}
          setBrands={setBrands}
          setJournals={setJournals}
          setIsSearchInputEmpty={setIsSearchInputEmpty}
        />
        {!isSearchInputEmpty && brands?.length === 0 && journals?.length === 0
          ? renderEmptyResults()
          : renderResults()}
      </div>
    );
  }
};

export default SearchOverlay;
