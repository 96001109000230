import React, { useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import MenuItem from '../menuItem';
import styles from './menuitems.module.scss';
import { BackButton } from '../../buttons/backButton';

// @ts-ignore
const MenuItems = ({ Data, showMenuItems, onClick }) => {
  const menuItemsRef = useRef(null);
  const [isSubnavigation, updateSubNavigation] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(Data);
  const [itemsStack, setItemsStack] = useState([Data]);
  const [move, changeMove] = useState('next');
  const [id, setId] = useState(null);
  const [mainCat, setMainCat] = useState('');

  // @ts-ignore
  const changeMenuItems = (newItemsToShow, newItemsStack, updatedMove, updatedId) => {
    setItemsToShow(newItemsToShow);
    setItemsStack(newItemsStack);
    changeMove(updatedMove);
    setId(updatedId);
  };

  // @ts-ignore
  const moveToNext = (targetItem) => {
    if (targetItem.items && targetItem.items.length > 0) {
      // @ts-ignore
      const newItems = itemsToShow.find((item) => item.value === targetItem.value);
      if (newItems !== undefined && 'items' in newItems && newItems.items.length > 0) {
        changeMenuItems(
          newItems.items,
          [...itemsStack, itemsToShow],
          'next',
          Math.random().toString(36).substr(2, 9)
        );
        updateSubNavigation(true);
        setMainCat(targetItem.value);
      }
    } else if (onClick) {
      onClick(targetItem);
    }
  };

  const moveToPrevious = () => {
    const newItemsStack = [...itemsStack];
    const newItemsToShow = newItemsStack.pop();

    if (newItemsToShow !== undefined) {
      changeMenuItems(
        newItemsToShow,
        newItemsStack,
        'prev',
        Math.random().toString(36).substr(2, 9)
      );
      updateSubNavigation(false);
    }
  };

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          timeout={200}
          key={id}
          classNames={{
            ...styles,
            enter: move === 'next' ? styles.enterLeft : styles.enterRight,
            exitActive: move === 'next' ? styles.exitActiveRight : styles.exitActiveLeft
          }}
        >
          <div
            className={`${styles.menuItems} ${showMenuItems ? 'showMenuItems' : 'hideMenuItems'}`}
            ref={menuItemsRef}
          >
            <div
              style={{
                paddingTop: isSubnavigation ? '31px' : '78px'
              }}
            >
              {/*// @ts-ignore*/}
              {itemsToShow.map((item) => {
                const checkItem = item.value;
                if (checkItem === 'back') {
                  return (
                    <div
                      style={{ paddingBottom: '15px' }}
                      key={Math.random().toString(36).substr(2, 9)}
                      onClick={() => moveToPrevious()}
                    >
                      <BackButton onClose={moveToPrevious} />
                      <p className={styles.mainCat}>{mainCat}:</p>
                      {/*<p className='backButton'>{item.value}</p>*/}
                    </div>
                  );
                }
                return (
                  <MenuItem
                    key={Math.random().toString(36).substr(2, 9)}
                    item={item}
                    moveToNext={moveToNext}
                    onClick={onClick}
                    // nextValue={item.hasOwnProperty('items') && item.items?.length > 0}
                  />
                );
              })}

              {!isSubnavigation && (
                <div className={styles.socialicons}>
                  <a
                    href='https://www.instagram.com/fairlyfab_official/'
                    target='_blank'
                    rel='noreferrer'
                  >
                    Instagram
                  </a>
                  <a href='https://www.facebook.com/fairlyfabcom' target='_blank' rel='noreferrer'>
                    Facebook
                  </a>
                </div>
              )}
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default MenuItems;
